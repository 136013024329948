import React, { useState } from "react";
import {
  Steinbruch,
  Holzfällerhütte,
  Erzmine,
  Bauernhof,
  Fischerhütte,
  Lagererweiterung,
  Werft,
  Schiff,
} from "../shared/buildings";
import Dialog from "./Dialog";
import BuildingInformation from "./BuildingInformation";
import ActionInformation from "./ActionsInformation";
import {
  faPeopleCarry,
  faDatabase,
  faHammer,
  faAnchor,
  faPlusCircle,
  faThLarge,
  faSquare,
} from "@fortawesome/free-solid-svg-icons";

const rohStoffBuildings = [
  Holzfällerhütte,
  Steinbruch,
  Erzmine,
  Bauernhof,
  Fischerhütte,
];

const other = [Lagererweiterung, Werft, Schiff];

const CostTable = () => {
  const [show, setShow] = useState(true);
  return (
    <div>
      {show && (
        <div style={{}}>
          <div style={{ fontSize: "1.3rem", marginTop: "20px" }}>
            Rohstoffgebäude:
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {rohStoffBuildings.map((b) => (
              <BuildingInformation building={b} />
            ))}
          </div>
          <div style={{ fontSize: "1.3rem", marginTop: "30px" }}>
            Sonstige Basisgebäude:
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {other.map((b) => (
              <BuildingInformation building={b} />
            ))}
          </div>

          <div style={{ fontSize: "1.3rem", marginTop: "30px" }}>Aktionen:</div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <ActionInformation
              title="Gebäudebau"
              iconColor="black"
              description="Baut das jeweilige Rohstoffgebäude - Kostet 4 AP wenn benachbart zum Lager, sonst 5 AP. "
              icon={faHammer}
              ap={"4 oder 5"}
            />
            <ActionInformation
              title="Träger"
              iconColor="black"
              description="Transport pro Feld"
              icon={faPeopleCarry}
              ap={"1"}
            />
            <ActionInformation
              title="Sammler"
              iconColor="black"
              description="Sammelt je 1 Rohstoff pro Feld (außer Erz). Benötigt Träger bis zum Lager."
              icon={faDatabase}
              ap={"2"}
            />
          </div>
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            <ActionInformation
              title="Terraforming"
              iconColor="black"
              description="Terraformt ein leeres Grasfeld in Holz, Stein, Getreide oder Wasser"
              icon={faThLarge}
              ap={"5"}
            />
            <ActionInformation
              title="Händlerkarte"
              iconColor="#ffa5ba"
              description="Ziehe pro AP 2 Händlerkarten und gebe je eine Karte zurück"
              icon={faSquare}
              ap={"1"}
            />
            <ActionInformation
              title="Spezialgebäude"
              iconColor="#fffb9b"
              description="Ziehe pro AP 2 Spezialgebäudekarten und gebe je eine Karte zurück "
              icon={faSquare}
              ap={"1"}
            />
          </div>
        </div>
      )}
      <p onClick={() => setShow(!show)}>
        {show ? "Kostentabelle verstecken" : "Kostentabelle anzeigen"}
      </p>
    </div>
  );
};

export default CostTable;
