import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnchor,
  faShip,
  faPlusCircle,
  faHammer,
  faDatabase,
  faCoins,
  faPeopleCarry,
  faTrash,
  faThLarge,
} from "@fortawesome/free-solid-svg-icons";
import { setFieldAction } from "../api/game";
import { FieldAction } from "../shared";

const styles = {
  icon: {
    margin: "2px 15px",
  },
};

const IconSize = "2x";

const ContextActions = ({ contextAction, setContextAction }: any) => {
  const callFieldAction = (actionType: FieldAction) => {
    setFieldAction(contextAction, actionType);
    setContextAction(null);
  };

  return (
    <div style={{ margin: "8px 0" }}>
      {contextAction.transform && (
        <FontAwesomeIcon
          onClick={() => {
            callFieldAction(FieldAction.Transform);
          }}
          icon={faThLarge}
          style={styles.icon}
          size={IconSize}
        />
      )}
      {contextAction.hafen && (
        <FontAwesomeIcon
          onClick={() => {
            callFieldAction(FieldAction.Hafen);
          }}
          icon={faAnchor}
          style={styles.icon}
          size={IconSize}
        />
      )}
      {contextAction.schiff && (
        <FontAwesomeIcon
          onClick={() => {
            callFieldAction(FieldAction.Ship);
          }}
          icon={faShip}
          style={styles.icon}
          size={IconSize}
        />
      )}
      {contextAction.buildLagererweiterung && (
        <FontAwesomeIcon
          onClick={() => {
            callFieldAction(FieldAction.IncreaseStorage);
          }}
          icon={faPlusCircle}
          style={styles.icon}
          size={IconSize}
        />
      )}
      {contextAction.build && (
        <FontAwesomeIcon
          onClick={() => {
            callFieldAction(FieldAction.BuildResourceBuilding);
          }}
          icon={faHammer}
          style={styles.icon}
          size={IconSize}
        />
      )}
      {contextAction.sammeln && (
        <FontAwesomeIcon
          onClick={() => {
            callFieldAction(FieldAction.Sammeln);
          }}
          icon={faDatabase}
          style={styles.icon}
          size={IconSize}
        />
      )}
      {contextAction.doppeltSammeln && (
        <FontAwesomeIcon
          onClick={() => {
            callFieldAction(FieldAction.DoppelSammeln);
          }}
          icon={faCoins}
          style={styles.icon}
          size={IconSize}
        />
      )}
      {contextAction.traeger && (
        <FontAwesomeIcon
          onClick={() => {
            callFieldAction(FieldAction.Traeger);
          }}
          icon={faPeopleCarry}
          style={styles.icon}
          size={IconSize}
        />
      )}
      {contextAction.clearPlan && (
        <FontAwesomeIcon
          onClick={() => {
            callFieldAction(FieldAction.ClearPlan);
          }}
          icon={faTrash}
          style={styles.icon}
          size={IconSize}
        />
      )}
    </div>
  );
};

export default ContextActions;
