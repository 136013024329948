export enum FieldAction {
  Sammeln = "sammeln",
  DoppelSammeln = "doppeltSammeln",
  Traeger = "traeger",
  ClearPlan = "clearPlan",
  Hafen = "hafen",
  Ship = "ship",
  IncreaseStorage = "increaseStorage",
  BuildResourceBuilding = "build",
  Transform = "transform",
}
