import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPeopleCarry,
  faHome,
  faWarehouse,
  faHammer,
  faDatabase,
  faAnchor,
  faQuestionCircle,
  faThLarge,
} from "@fortawesome/free-solid-svg-icons";

const iconStyle = { fontSize: "25px", margin: "2px" };

const IslandTile = ({ tile, onPress, active, hidden }: any) => {
  const {
    terrain,
    lagerListe,
    gebaeudeListe,
    traegerListe,
    sammlerListe,
    bauListe,
    hafenListe,
    transformListe,
  } = tile;

  const terrainTypes = [
    { type: "holz" },
    { type: "stein" },
    { type: "erz" },
    { type: "getreide" },
    { type: "wasser" },
    { type: "gras" },
  ];

  return (
    <div
      className={
        "field " +
        (!!hidden ? "hidden" : terrainTypes[terrain].type) +
        (active ? " active" : "")
      }
      onClick={onPress}
    >
      <div className="bg" />
      <div className="terrain">
        {!!hidden && (
          <FontAwesomeIcon icon={faQuestionCircle} color={"white"} size="3x" />
        )}
      </div>

      <div style={{ zIndex: 3 }}>
        {gebaeudeListe &&
          gebaeudeListe.map((element: string) => (
            <FontAwesomeIcon icon={faHome} style={iconStyle} color={element} />
          ))}
        {hafenListe &&
          hafenListe.map((element: string) => (
            <FontAwesomeIcon
              icon={faAnchor}
              style={iconStyle}
              color={element}
            />
          ))}
        {transformListe &&
          transformListe.map((element: string) => (
            <FontAwesomeIcon
              icon={faThLarge}
              style={iconStyle}
              color={element}
            />
          ))}
        {bauListe &&
          bauListe.map((element: string) => (
            <FontAwesomeIcon
              icon={faHammer}
              style={iconStyle}
              color={element}
            />
          ))}
      </div>
      <div style={{ zIndex: 3 }}>
        {traegerListe &&
          traegerListe.map((element: string) => (
            <FontAwesomeIcon
              icon={faPeopleCarry}
              style={iconStyle}
              color={element}
            />
          ))}
        {sammlerListe &&
          sammlerListe.map((element: string) => (
            <FontAwesomeIcon
              icon={faDatabase}
              style={iconStyle}
              color={element}
            />
          ))}
      </div>
      <div style={{ zIndex: 3 }}>
        {lagerListe &&
          lagerListe.map((element: string) => (
            <FontAwesomeIcon
              icon={faWarehouse}
              style={iconStyle}
              color={element}
            />
          ))}
      </div>
    </div>
  );
};

export default IslandTile;
