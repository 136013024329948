import React, { useState } from "react";
import RoomList from "../components/RoomList";
import {
  joinRoom,
  addRoom,
  joinGame,
  leaveRoom,
  startGame,
} from "../api/rooms";
import { GameRoomType, PlayerType, NewRoomType } from "../shared";
import GameRoom from "../components/GameRoom";
import styled from "styled-components";
import NewGameDialog from "../components/NewGameDialog";
import Button from "../components/Button";
import {
  faPeopleCarry,
  faCoins,
  faDatabase,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  games: GameRoomType[];
  players: PlayerType[];
  myRoom: string | null;
  myPlayername: string | null;
}

const StyledContainer = styled.div`
  max-width: 500px;
  margin: auto;
  margin-top: 100px;
`;

const RoomsPage = ({ games, players, myRoom, myPlayername }: Props) => {
  const [showNewGameDialog, setShowNewGameDialog] = useState(false);

  const CreateNewGame = (addRoomRequest: NewRoomType) => {
    console.log(addRoomRequest);
    addRoom(addRoomRequest);
    setShowNewGameDialog(false);
  };

  return (
    <StyledContainer>
      {!showNewGameDialog && !myRoom && (
        <>
          <h1 style={{ fontSize: "2.5rem", textAlign: "center" }}>
            <FontAwesomeIcon icon={faPeopleCarry} /> Träger &amp; Sammler{" "}
            <FontAwesomeIcon icon={faDatabase} />
          </h1>

          <RoomList
            joinGameLobby={joinRoom}
            games={games}
            addGame={() => setShowNewGameDialog(true)}
          ></RoomList>
          <Button
            title="Anleitung"
            onClick={() => {
              window.location.href =
                "https://www.youtube.com/watch?v=8f3TZFpLCLM";
            }}
            style={{ marginTop: "10px", textAlign: "center" }}
          ></Button>
          <Button
            title="Feedback"
            onClick={() => {
              window.location.href =
                "https://florianwittmann.typeform.com/to/ipSumw";
            }}
            style={{ marginTop: "10px", textAlign: "center" }}
          ></Button>
        </>
      )}
      {!showNewGameDialog &&
        myRoom &&
        games.find((ele) => ele.id === myRoom) && (
          <GameRoom
            onJoinGame={joinGame}
            onStart={startGame}
            inGame={!!myPlayername}
            players={players}
            onLeaveRoom={() => leaveRoom(myRoom, myPlayername)}
            gameroom={games.find((ele) => ele.id === myRoom)!}
            myPlayername={myPlayername}
          ></GameRoom>
        )}
      {showNewGameDialog && (
        <NewGameDialog
          onSubmit={CreateNewGame}
          onCancel={() => {
            setShowNewGameDialog(false);
          }}
        ></NewGameDialog>
      )}
    </StyledContainer>
  );
};

export default RoomsPage;
