import { FieldEnum } from "./enums/Fields";
import {
  Holzfällerhütte,
  Steinbruch,
  Fischerhütte,
  Bauernhof,
  Erzmine,
} from "./buildings";
import { ResourcesType } from "./interfaces/ResourcesType";
import { FieldType } from "./interfaces/FieldType";
import { GamestateType } from "./interfaces/GamestateType";
import { FieldChoiceType } from "./interfaces/FieldChoiceType";
import { PlayerType } from "./interfaces/PlayerType";

export const canStore = (
  provided: ResourcesType,
  resources: ResourcesType,
  maxLager: ResourcesType
): boolean => {
  return (
    (provided.holz > 0 && resources.holz < maxLager.holz) ||
    (provided.stein > 0 && resources.stein < maxLager.stein) ||
    (provided.erz > 0 && resources.erz < maxLager.erz) ||
    (provided.nahrung > 0 && resources.nahrung < maxLager.nahrung)
  );
};

export const canPay = (
  cost: ResourcesType,
  resources: ResourcesType
): boolean => {
  return (
    resources.holz >= cost.holz &&
    resources.stein >= cost.stein &&
    resources.erz >= cost.erz &&
    resources.nahrung >= cost.nahrung
  );
};

export const afterPayed = (
  cost: ResourcesType,
  resources: ResourcesType
): ResourcesType => {
  return {
    holz: resources.holz - cost.holz,
    stein: resources.stein - cost.stein,
    erz: resources.erz - cost.erz,
    nahrung: resources.nahrung - cost.nahrung,
  };
};

export const afterStored = (
  provided: ResourcesType,
  resources: ResourcesType,
  maxLager: ResourcesType
): ResourcesType => {
  return {
    holz: Math.min(resources.holz + provided.holz, maxLager.holz),
    stein: Math.min(resources.stein + provided.stein, maxLager.stein),
    erz: Math.min(resources.erz + provided.erz, maxLager.erz),
    nahrung: Math.min(resources.nahrung + provided.nahrung, maxLager.nahrung),
  };
};
export const increaseStore = (
  maxLager: ResourcesType,
  addLager: ResourcesType
): ResourcesType => {
  return {
    holz: maxLager.holz + addLager.holz,
    stein: maxLager.stein + addLager.stein,
    erz: maxLager.erz + addLager.erz,
    nahrung: maxLager.nahrung + addLager.nahrung,
  };
};

export const addProvides = (
  providesA: ResourcesType,
  providesB: ResourcesType
): ResourcesType => {
  return {
    holz: providesA.holz + providesB.holz,
    stein: providesA.stein + providesB.stein,
    erz: providesA.erz + providesB.erz,
    nahrung: providesA.nahrung + providesB.nahrung,
  };
};

export const getNeededTraeger = (
  traeger: number[],
  fieldIndex: number,
  startIndex: number
): number[] => {
  if (startIndex === fieldIndex) return [];
  const needed: number[] = [];

  if (!traeger.includes(fieldIndex)) {
    needed.push(fieldIndex);
  }
  if (!isConnected(fieldIndex, startIndex)) {
    const listBetween = connected[fieldIndex].filter((value) =>
      connected[startIndex].includes(value)
    );
    // Check for traeger already set...
    if (listBetween.filter((value) => traeger.includes(value)).length === 0) {
      // Additional Traeger needed
      needed.push(pickRandomOf(listBetween));
    }
  }
  return needed;
};

export const isReachableWithTraeger = (
  traeger: number[],
  startIndex: number,
  fieldIndex: number
): boolean => {
  if (startIndex === fieldIndex) return true;
  if (!traeger.includes(fieldIndex)) return false;
  const checked: number[] = [];
  const reachable = [fieldIndex];
  while (reachable.length > 0) {
    const t = reachable.pop()!;
    checked.push(t);
    if (t === startIndex) return true;
    const next = connected[t].filter(
      (nextT) =>
        (traeger.includes(nextT) || nextT === startIndex) &&
        !checked.includes(nextT) &&
        !reachable.includes(nextT)
    );
    if (next.length > 0) reachable.push(...next);
  }
  return false;
};

export const getRandomInt = (min: number, max: number): number => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

// export const arrangeFieldsForPrint = islandElements => {
//   return [
//     [islandElements[0], islandElements[1]],
//     [islandElements[2], islandElements[3], islandElements[4]],
//     [islandElements[5], islandElements[6]]
//   ];
// };

export const getBuildingForField = (field: FieldType) => {
  switch (field.terrain) {
    case FieldEnum.Holz:
      return Holzfällerhütte;
    case FieldEnum.Stein:
      return Steinbruch;
    case FieldEnum.Wasser:
      return Fischerhütte;
    case FieldEnum.Getreide:
      return Bauernhof;
    case FieldEnum.Erz:
      return Erzmine;
    default:
      return null;
  }
};

export const connected: { [key: number]: number[] } = {
  0: [1, 2, 3],
  1: [0, 3, 4],
  2: [0, 3, 5],
  3: [0, 1, 2, 4, 5, 6],
  4: [1, 3, 6],
  5: [2, 3, 6],
  6: [3, 4, 5],
};

export const isConnected = (fieldA: number, fieldB: number) => {
  return connected[fieldA].indexOf(fieldB) !== -1;
};

export const pickRandomOf = <T>(list: T[]): T => {
  return list[getRandomInt(0, list.length - 1)];
};

export const randomizeList = <T>(list: T[]): T[] =>
  list
    .map((a) => ({ sort: Math.random(), value: a }))
    .sort((a, b) => a.sort - b.sort)
    .map((a) => a.value);

export const getField = (gamestate: GamestateType, choice: FieldChoiceType) =>
  gamestate.islands[choice.island][choice.field];

function ensure<T>(
  argument: T | undefined | null,
  message: string = "This value was promised to be there."
): T {
  if (argument === undefined || argument === null) {
    throw new TypeError(message);
  }

  return argument;
}

export function findPlayerByName(
  players: PlayerType[],
  playername: string
): PlayerType | undefined {
  const p = players.find((p) => p.name === playername);
  return p;
}

export function findPlayerByColor(
  players: PlayerType[],
  color: string
): PlayerType | undefined {
  const p = players.find((p) => p.color === color);
  return p;
}
