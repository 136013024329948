import React from "react";
import SpecialBuildingCard from "./SpecialBuildingCard";
import { PlayerType } from "../shared";
import { findPlayerByColor } from "../shared/utils";
import DialogHeader from "./DialogHeader";
import { ActionSheet, ActionSheetOutSide } from "./ActionSheet";
import Dialog from "./Dialog";
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";

interface Props {
  players: PlayerType[];
  playerColor: string;
  onCardClick: Function;
  onBackClick: Function;
  myPlayerColor: string;
}

const ViewOpponentsSpecialBuildingCards = ({
  players,
  playerColor,
  onCardClick,
  onBackClick,
  myPlayerColor,
}: Props) => {
  const player = findPlayerByColor(players, playerColor);
  return (
    <>
      <ActionSheetOutSide></ActionSheetOutSide>
      <ActionSheet onClick={() => onBackClick()}>
        <Dialog style={{ zIndex: 102 }}>
          <DialogHeader
            title={
              myPlayerColor === playerColor
                ? "Bereits gebaute Spezialgebäude"
                : "Spezialgebäude von " + player?.name
            }
            iconLeft={faArrowCircleLeft}
            onIconLeftClick={onBackClick}
          ></DialogHeader>
          <div
            style={{
              display: "inline-flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {player &&
              player.specialBuildings.map((card) => (
                <SpecialBuildingCard
                  card={card}
                  onClick={() => onCardClick()}
                ></SpecialBuildingCard>
              ))}
          </div>
        </Dialog>
      </ActionSheet>
    </>
  );
};

export default ViewOpponentsSpecialBuildingCards;
