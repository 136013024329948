import React from "react";
import styled from "styled-components";
import { Colors } from "../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faShuttleVan,
  faDoorOpen,
  faSignOutAlt,
  faCog,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { leaveRunningGame } from "../api/rooms";
import { VictoryConditionType } from "../shared/interfaces/VictoryConditionType";
import colorContrast from "color-contrast";

interface Props {
  victoryConditions: VictoryConditionType[];
  onCardsClick: Function;
  onShowOptions: Function;
}

const Container = styled.div`
  height: 100px;
  background-color: ${Colors.color2};
  border-top: 2px solid ${Colors.color1};
  z-index: 20;
`;

const VictoryConditionBox = styled.div`
  padding: 10px;
  margin: 5px;
  background-color: #a4f7f7;
  border: 2px solid ${Colors.color1};
  border-radius: 4px;

  @media (max-width: 1230px) {
    padding: 5px;
    margin: 1px;
  }
`;

const GameBottomBar = ({
  victoryConditions,
  onCardsClick,
  onShowOptions,
}: Props) => {
  return (
    <Container style={{ textAlign: "center", display: "flex" }}>
      <div
        style={{ textAlign: "center", display: "flex", overflowX: "auto" }}
        onClick={() => onCardsClick()}
      >
        {victoryConditions.map((condition) => (
          <VictoryConditionBox>
            <div style={{ whiteSpace: "nowrap" }}>
              {condition.card.shortname}
            </div>
            {condition.currentMaxLevel !== -1 ? (
              <div style={{ whiteSpace: "nowrap" }}>
                {condition.card.levels[condition.currentMaxLevel].text}
                {!condition.card
                  .canBeReachedByMultiplePlayersInDifferentRounds &&
                  ` (${condition.currentMaxLevel + 1}/${
                    condition.card.levels.length
                  })`}
              </div>
            ) : (
              <div style={{ whiteSpace: "nowrap" }}>-</div>
            )}
            <div style={{ display: "flex" }}>
              {condition.playersFulfillingMaxLevel.map((p) => (
                <div
                  style={{
                    padding: "4px",
                    marginTop: "4px",
                    marginRight: "2px",
                    flex: 1,
                    backgroundColor: p,
                    fontSize: "0.8rem",
                    borderRadius: "2px",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faCheck}
                    color={
                      colorContrast(p, "white") > colorContrast(p, "black")
                        ? "white"
                        : "black"
                    }
                  ></FontAwesomeIcon>
                </div>
              ))}
            </div>
          </VictoryConditionBox>
        ))}
      </div>
      <div style={{ flex: 1 }}></div>
      <div style={{ margin: 20 }}>
        <FontAwesomeIcon
          onClick={() => onShowOptions()}
          color="white"
          size="2x"
          icon={faCog}
        ></FontAwesomeIcon>
      </div>
    </Container>
  );
};

export default GameBottomBar;
