import React from "react";

import styled from "styled-components";

interface Props {
  title: string;
  onClick: React.MouseEventHandler;
  style?: React.CSSProperties;
  enabled?: boolean;
}

const Button = ({ title, onClick, style, enabled = true }: Props) => {
  const OuterButton = styled.div`
    border: 5px solid #886644;
    background-color: #b7916a;
    padding: 15px;
    border-radius: 9px;
    opacity: ${enabled ? 1 : 0.3};
  `;
  const InnerButton = styled.div`
    font-size: 30px;
    color: white;
  `;

  return (
    <OuterButton onClick={enabled ? onClick : () => {}} style={style}>
      <InnerButton>{title}</InnerButton>
    </OuterButton>
  );
};

export default Button;
