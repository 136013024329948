import { GamestateType, PlayerType } from "../shared";
import { isConnected } from "../shared/utils";

export const APneeded = (
  gamestate: GamestateType,
  players: PlayerType[],
  myPlayername: string
) => {
  if (myPlayername === null || gamestate.state !== "gameround") return 0;
  const myPlayer = players.find((p) => p.name === myPlayername)!;
  const myColor = myPlayer.color;
  let ap = 0;
  ap = ap + myPlayer.drawMerchantCards + myPlayer.drawSpecialBuildingCards;
  if (myPlayer.buildSpecialBuilding !== null) {
    ap = ap + 3;
  }
  for (var i = 0; i < gamestate.islands.length; i++) {
    const island = gamestate.islands[i];
    const lagerIndex = island.findIndex((field) =>
      field.lagerListe.includes(myColor)
    );
    if (lagerIndex > -1) {
      for (var fieldIndex = 0; fieldIndex < 7; fieldIndex++) {
        const f = island[fieldIndex];
        if (f.bauListe.includes(myColor)) {
          if (lagerIndex === fieldIndex) {
            ap = ap + 3;
          } else if (isConnected(lagerIndex, fieldIndex)) {
            ap = ap + 4;
          } else {
            ap = ap + 5;
          }
        }
        if (f.traegerListe.includes(myColor)) {
          ap = ap + 1;
        }
        if (f.sammlerListe.includes(myColor)) {
          ap = ap + f.sammlerListe.filter((c) => c === myColor).length * 2;
        }
        if (f.transformListe.includes(myColor)) {
          ap = ap + 5;
        }
      }
    }
  }
  return ap;
};
