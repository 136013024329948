import styled from "styled-components";

export default styled.div`
  background-color: #ffa5ba;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23f06fed' fill-opacity='0.21' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
  width: 60px;
  height: 100px;
  text-align: center;
  display: inline-flex;
  border-radius: 9px;
  border: 1px solid gray;
  font-size: 30px;
  flex-direction: column;
  justify-content: space-around;
  margin: 5px;
  color: black;
`;
