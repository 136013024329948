import socket from "./socket";
import { SocketMessage, NewRoomType } from "../shared";

export const joinRoom = (id: number, password: string) => {
  socket.emit(SocketMessage.JoinRoom, id, password);
};

export const addRoom = (addRoomRequest: NewRoomType) => {
  console.log(addRoomRequest);
  socket.emit(SocketMessage.AddRoom, addRoomRequest);
};

export const joinGame = (playername: string, color: string) => {
  socket.emit(SocketMessage.JoinGame, playername, color);
};

export const leaveRoom = (id: string, playername: string | null) => {
  socket.emit(SocketMessage.LeaveRoom, id, playername);
};

export const startGame = (playername: string) => {
  socket.emit(SocketMessage.StartGame, playername);
};

export const leaveRunningGame = () => {
  socket.emit("leaveRunningGame");
};
