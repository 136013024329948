import React from "react";

import styled from "styled-components";
import { Colors } from "../theme";

interface Props {
  children: React.ReactNode;
  style?: React.CSSProperties;
}

const OuterDialog = styled.div`
  border: 5px solid ${Colors.color1};
  background-color: ${Colors.color3};
  padding: 5px;
  border-radius: 9px;
`;
const InnerDialog = styled.div`
  background-color: ${Colors.color2};
  padding: 15px;
  font-size: 30px;
  color: white;
  border-radius: 2px;
  text-align: center;
`;
const Dialog = ({ children, style }: Props) => {
  return (
    <OuterDialog style={style}>
      <InnerDialog>{children}</InnerDialog>
    </OuterDialog>
  );
};

export default Dialog;
