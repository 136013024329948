import React from "react";

import styled from "styled-components";

const StyledInput = styled.input`
  border: 5px solid #886644;
  background-color: #b7916a;
  padding: 15px;
  border-radius: 9px;
  color: white;
  font-size: 20px;
`;

const Input = ({ ...args }) => {
  return <StyledInput {...args} />;
};

export default Input;
