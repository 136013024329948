import React, { useState } from "react";
import MerchantCard from "./MerchantCard";

const UsageMerchantCards = ({ merchantCards, onCardClick, resources }) => {
  return (
    <div
      style={{
        display: "inline-flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
      }}
    >
      {merchantCards.map((card, i) => (
        <MerchantCard
          card={card}
          resources={resources}
          onDealTaken={(dealnr, times) => onCardClick(i, dealnr, times)}
        ></MerchantCard>
      ))}
    </div>
  );
};

export default UsageMerchantCards;
