import React, { useEffect, useState } from "react";
import socket from "./api/socket";
import RoomsPage from "./pages/RoomsPage";
import { SocketMessage, GamestateType } from "./shared";
import GamePage from "./pages/GamePage";
import "./App.css";
import { BackgroundTexture } from "./components/BackgroundTexture";
import { APneeded } from "./services/apService";

function App() {
  const [ap, setAP] = useState(0);
  const [myPlayername, setMyPlayername] = useState<string | null>(null);
  const [players, setPlayers] = useState([]);
  const [games, setGames] = useState([]);
  const [gamestate, setGamestate] = useState<GamestateType | null>(null);
  const [myRoom, setMyRoom] = useState<string | null>(null);

  useEffect(() => {
    socket.on("validate game", function () {
      console.log("connect/reconnect triggered");
      const activeGame = localStorage.getItem("activeGame");
      if (activeGame) {
        const game = JSON.parse(activeGame);
        socket.emit("reconnectGame", game.room, game.playername);
      }
    });

    socket.on("games", (data) => {
      setGames(data);
    });

    socket.on("players", (data) => {
      setPlayers(data);
      if (gamestate && myPlayername) {
        setAP(APneeded(gamestate, data, myPlayername));
      }
    });
    socket.on("gamestate", (data) => {
      setGamestate(data);
      if (data && myPlayername) {
        setAP(APneeded(data, players, myPlayername));
      }
    });
    socket.on("gamechange", (newGamestate, newPlayers) => {
      setGamestate(newGamestate);
      setPlayers(newPlayers);
      if (newGamestate && newPlayers && myPlayername) {
        setAP(APneeded(newGamestate, newPlayers, myPlayername));
      }
    });

    socket.on("welcomeInRoom", (room) => setMyRoom(room));
    socket.on(
      SocketMessage.JoinGameSuccess,
      (playername: string, room: string) => {
        setMyPlayername(playername);
        localStorage.setItem(
          "activeGame",
          JSON.stringify({ playername, room })
        );
      }
    );
    socket.on(SocketMessage.LeaveRoomSuccess, () => {
      setGamestate(null);
      setPlayers([]);
      setMyPlayername(null);
      setMyRoom(null);
    });

    return () => {
      socket.removeAllListeners();
    };
  }, [players, myPlayername, gamestate]);

  return (
    <div className="App" style={{ height: "100%" }}>
      <BackgroundTexture></BackgroundTexture>
      {!(gamestate && gamestate.started) && (
        <>
          <RoomsPage
            games={games}
            players={players}
            myRoom={myRoom}
            myPlayername={myPlayername}
          ></RoomsPage>
          <div
            style={{
              marginTop: "2rem",
              color: "darkblue",
              textAlign: "center",
            }}
          >
            <a href="https://www.florianwittmann.de/impressum">Impressum</a> |{" "}
            <a href="https://www.florianwittmann.de/datenschutz">Datenschutz</a>
          </div>
        </>
      )}
      {gamestate && gamestate.started && myPlayername && (
        <GamePage
          gamestate={gamestate}
          players={players}
          myRoom={myRoom}
          myPlayername={myPlayername}
          ap={ap}
        ></GamePage>
      )}
    </div>
  );
}

export default App;
