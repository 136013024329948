import socket from "./socket";
import { FieldAction, FieldEnum } from "../shared";

export const setFieldAction = (contextAction: any, type: FieldAction) => {
  const { island, field } = contextAction;
  console.log("emit " + type);
  socket.emit(type, { island, field });
};

export const trade = (cardindex: number, tradeindex: number, times: number) => {
  console.log("trade", cardindex, tradeindex, times);
  socket.emit("trade", cardindex, tradeindex, times);
};

export const buildSpecialCard = (cardindex: number | null) => {
  socket.emit("buildSpecialCard", cardindex);
};

export const drawMerchantCard = () => {
  socket.emit("drawMerchant");
};

export const returnMerchantCard = (index: number) => {
  socket.emit("returnMerchantCard", index);
};

export const drawSpecialBuildingCard = () => {
  socket.emit("drawSpecialBuilding");
};

export const returnSpecialBuildingCard = (index: number) => {
  socket.emit("returnSpecialBuilding", index);
};

export const chooseTerraforming = (terra: string) => {
  socket.emit("chooseTerraforming", terra);
};

export const submitRound = () => {
  socket.emit("submitRound");
};

export const undoSubmitRound = () => {
  socket.emit("undoSubmitRound");
};
