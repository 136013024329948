import React, { useState } from "react";
import Button from "./Button";
import Dialog from "./Dialog";
import Input from "./Input";
import { NewRoomType, ResourceDistributionEnum } from "../shared";
import DialogHeader from "./DialogHeader";
import { faArrowCircleLeft, faCheck } from "@fortawesome/free-solid-svg-icons";
import ToggleButton from "./ToggleButton";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import playerColors from "../shared/playerColors";
import colorContrast from "color-contrast";

const ColorSelectors = styled.div`
  display: flex;
  justify-content: center;
`;
const ColorSelector = styled.div`
  padding: 8px;
  height: 50px;
  width: 50px;
  border-radius: 99px;
  margin: 5px;
`;

interface Props {
  onSubmit: (arg: NewRoomType) => void;
  onCancel: React.MouseEventHandler;
}

const NewGameDialog = ({ onSubmit, onCancel }: Props) => {
  const [playerName, setPlayerName] = useState("");
  const [title, setTitle] = useState("");
  const [maxPlayer, setMaxPlayer] = useState(6);
  const [hasPassword, setHasPassword] = useState(false);
  const [password, setPassword] = useState<string | null>(null);
  const [playerColor, setPlayerColor] = useState<string | null>(null);
  const [resourceDistribution, setResourceDistribution] = useState<
    ResourceDistributionEnum
  >(ResourceDistributionEnum.Empfohlen);

  return (
    <Dialog style={{ textAlign: "center" }}>
      <DialogHeader
        title={"Neues Spiel erstellen"}
        iconLeft={faArrowCircleLeft}
        onIconLeftClick={onCancel}
      ></DialogHeader>
      <>
        <div>
          <div style={{ margin: "15px 0 5px 0" }}>Spielname:</div>
          <Input
            onChange={(event: any) => setTitle(event.target.value)}
            value={title}
            maxLength="20"
            placeholder="Spielname"
          ></Input>
        </div>
        <div>
          <div style={{ margin: "15px 0 5px 0" }}>Spielername:</div>
          <Input
            onChange={(event: any) => setPlayerName(event.target.value)}
            value={playerName}
            maxLength="10"
            placeholder="Dein Name?"
          ></Input>
        </div>

        <div>
          <div style={{ margin: "15px 0 5px 0" }}>Bitte wähle deine Farbe:</div>
          <ColorSelectors>
            {playerColors.map((color) => {
              const contrastColor =
                colorContrast(color, "white") > colorContrast(color, "black")
                  ? "white"
                  : "black";
              return (
                <ColorSelector
                  onClick={() => setPlayerColor(color)}
                  style={{
                    backgroundColor: color,
                  }}
                >
                  {playerColor === color && (
                    <FontAwesomeIcon
                      icon={faCheck}
                      color={contrastColor}
                    ></FontAwesomeIcon>
                  )}
                </ColorSelector>
              );
            })}
          </ColorSelectors>
        </div>

        <div>
          <div style={{ margin: "15px 0 5px 0" }}>Spieleranzahl:</div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            {[1, 2, 3, 4, 5, 6].map((nr) => (
              <ToggleButton
                style={{ marginRight: "5px" }}
                onClick={() => {
                  setMaxPlayer(nr);
                }}
                title={nr.toString()}
                active={maxPlayer === nr}
              ></ToggleButton>
            ))}
          </div>
        </div>

        <div>
          <div style={{ margin: "15px 0 5px 0" }}>Resourcenverteilung:</div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ToggleButton
              style={{ marginRight: "5px" }}
              onClick={() => {
                setResourceDistribution(ResourceDistributionEnum.ErstesSpiel);
              }}
              title={ResourceDistributionEnum.ErstesSpiel}
              active={
                resourceDistribution === ResourceDistributionEnum.ErstesSpiel
              }
            ></ToggleButton>
            <ToggleButton
              style={{ marginRight: "5px" }}
              onClick={() => {
                setResourceDistribution(ResourceDistributionEnum.Empfohlen);
              }}
              title={ResourceDistributionEnum.Empfohlen}
              active={
                resourceDistribution === ResourceDistributionEnum.Empfohlen
              }
            ></ToggleButton>
            <ToggleButton
              style={{ marginRight: "5px" }}
              onClick={() => {
                setResourceDistribution(ResourceDistributionEnum.Zufall);
              }}
              title={ResourceDistributionEnum.Zufall}
              active={resourceDistribution === ResourceDistributionEnum.Zufall}
            ></ToggleButton>
          </div>
        </div>

        <div>
          <div style={{ margin: "15px 0 5px 0" }}>Spielbeitritt:</div>
          <div style={{ display: "flex", justifyContent: "center" }}>
            <ToggleButton
              style={{ marginRight: "5px" }}
              onClick={() => {
                setHasPassword(false);
                setPassword(null);
              }}
              title={"Ohne Passwort"}
              active={!hasPassword}
            ></ToggleButton>
            <ToggleButton
              style={{ marginRight: "5px" }}
              onClick={() => {
                setHasPassword(true);
              }}
              title={"Mit Passwort"}
              active={hasPassword}
            ></ToggleButton>
          </div>
          {hasPassword && (
            <Input
              onChange={(event: any) => setPassword(event.target.value)}
              value={password}
              placeholder="Passwort?"
            ></Input>
          )}
        </div>

        <Button
          style={{ marginTop: "15px" }}
          enabled={
            playerName.trim().length > 1 &&
            playerName.trim().length <= 10 &&
            title.trim().length > 2 &&
            title.trim().length <= 20 &&
            playerColor !== null &&
            (!hasPassword || (hasPassword && !!password))
          }
          onClick={() => {
            const pName = playerName.trim();
            const roomTitle = title.trim();
            if (
              pName.length > 1 &&
              pName.length <= 10 &&
              roomTitle.length > 2 &&
              roomTitle.length <= 20 &&
              playerColor !== null &&
              (!hasPassword || (hasPassword && !!password))
            )
              onSubmit({
                title: roomTitle,
                playername: pName,
                color: playerColor!,
                maxPlayer,
                password,
                resourceDistribution,
              });
          }}
          title="Spiel erstellen"
        ></Button>
      </>
    </Dialog>
  );
};

export default NewGameDialog;
