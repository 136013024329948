import React, { useState } from "react";
import Button from "./Button";
import Dialog from "./Dialog";
import Input from "./Input";
import { PlayerType, GameRoomType } from "../shared";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleLeft, faCheck } from "@fortawesome/free-solid-svg-icons";
import DialogHeader from "./DialogHeader";
import styled from "styled-components";
import colorContrast from "color-contrast";
import playerColors from "../shared/playerColors";

interface Props {
  onJoinGame: Function;
  onStart: Function;
  onLeaveRoom: React.MouseEventHandler;
  inGame: boolean;
  players: PlayerType[];
  gameroom: GameRoomType;
  myPlayername: string | null;
}

const PlayerList = styled.div`
  margin: 20px 0;
`;
const PlayerRow = styled.div`
  font-size: 20px;
  padding: 10px 0;
  margin-top: 8px;
  border-radius: 4px;
`;

const ColorSelectors = styled.div`
  display: flex;
  justify-content: center;
`;
const ColorSelector = styled.div`
  padding: 8px;
  height: 50px;
  width: 50px;
  border-radius: 99px;
  margin: 5px;
`;

const GameRoom = ({
  onJoinGame,
  players,
  inGame,
  onStart,
  onLeaveRoom,
  gameroom,
  myPlayername,
}: Props) => {
  const [playerName, setPlayerName] = useState("");
  const [playerColor, setPlayerColor] = useState<string | null>(null);

  return (
    <Dialog style={{ textAlign: "center" }}>
      <DialogHeader
        title={gameroom.title}
        iconLeft={faArrowCircleLeft}
        onIconLeftClick={onLeaveRoom}
      ></DialogHeader>

      <div>
        {players.length} / {gameroom.maxPlayer} Spieler
      </div>
      <div>Inseln: {gameroom.resourceDistribution}</div>
      <PlayerList>
        {players.map((player) => {
          const textOnPlayerColor =
            colorContrast(player.color, "white") >
            colorContrast(player.color, "black")
              ? "white"
              : "black";
          return (
            <PlayerRow
              style={{
                backgroundColor: player.color,
                color: textOnPlayerColor,
              }}
            >
              {player.name}
            </PlayerRow>
          );
        })}
      </PlayerList>
      {!inGame && gameroom.maxPlayer > players.length && (
        <>
          <Input
            style={{ width: "100%", textAlign: "center", margin: "20px 0" }}
            onChange={(event: any) => setPlayerName(event.target.value)}
            value={playerName}
            placeholder="Dein Name?"
            maxLength="10"
          ></Input>

          <div>
            <div>Bitte wähle deine Farbe:</div>
            <ColorSelectors>
              {playerColors.map((color) => {
                const available = !players.find((p) => p.color === color);
                const contrastColor =
                  colorContrast(color, "white") > colorContrast(color, "black")
                    ? "white"
                    : "black";
                return (
                  available && (
                    <ColorSelector
                      onClick={() => setPlayerColor(color)}
                      style={{
                        backgroundColor: color,
                      }}
                    >
                      {playerColor === color && (
                        <FontAwesomeIcon
                          icon={faCheck}
                          color={contrastColor}
                        ></FontAwesomeIcon>
                      )}
                    </ColorSelector>
                  )
                );
              })}
            </ColorSelectors>
          </div>

          <Button
            style={{ marginTop: "15px" }}
            onClick={() => {
              if (
                playerName.trim().length > 1 &&
                playerName.trim().length <= 10
              )
                onJoinGame(playerName.trim(), playerColor);
            }}
            title="Spiel beitreten"
            enabled={
              !!playerColor &&
              !!playerName &&
              !players.find((p) => p.color === playerColor)
            }
          ></Button>
        </>
      )}

      {!inGame &&
        gameroom.maxPlayer === players.length &&
        "Dieses Spiel ist bereits vollständig. Suche dir ein anderes Spiel oder starte ein eigenes."}

      {inGame && players.length > 0 && players[0].name === myPlayername && (
        <Button
          style={{ marginTop: "15px" }}
          onClick={() => onStart()}
          title="Alle Spieler sind da!"
        ></Button>
      )}
      {inGame && players.length > 0 && players[0].name !== myPlayername && (
        <div>Warten auf Start des Spiels...</div>
      )}
    </Dialog>
  );
};

export default GameRoom;
