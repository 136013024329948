import React from "react";

import styled from "styled-components";
import { canPay } from "../shared/utils";
import { VictoryConditionType } from "../shared/interfaces/VictoryConditionType";
import colorContrast from "color-contrast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

interface Props {
  victoryCondition: VictoryConditionType;
}

const Card = styled.div`
  background-color: #a4f7f7;
  width: 250px;
  height: 300px;
  text-align: center;
  display: inline-flex;
  border-radius: 9px;
  border: 1px solid gray;
  font-size: 20px;
  flex-direction: column;
  justify-content: space-around;
  margin: 5px;
  color: black;
  position: relative;
  padding: 10px;
`;

const LevelList = styled.div`
  margin-top: 5px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
`;

const Level = styled.div`
  padding: 10px 15px;
  background-color: #60e4e4;
  margin: 4px;
  border-radius: 4px;
  flex-wrap: wrap;
`;

const VictoryConditionCard = ({ victoryCondition }: Props) => {
  const ownStyle = {};

  return (
    <div>
      <Card>
        {victoryCondition.card.description}
        <LevelList>
          {victoryCondition.card.levels.map((level, i) => (
            <Level>
              {level.text}
              {(i === victoryCondition.currentMaxLevel - 1 ||
                (i === victoryCondition.card.levels.length - 1 &&
                  victoryCondition.currentMaxLevel === -1)) && (
                <div style={{ display: "flex" }}>
                  {victoryCondition.playersFulfillingMaxLevel.map((p) => (
                    <div
                      style={{
                        padding: "4px",
                        marginTop: "4px",
                        marginRight: "2px",
                        flex: 1,
                        backgroundColor: p,
                        fontSize: "0.8rem",
                        borderRadius: "2px",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={faCheck}
                        color={
                          colorContrast(p, "white") > colorContrast(p, "black")
                            ? "white"
                            : "black"
                        }
                      ></FontAwesomeIcon>
                    </div>
                  ))}
                </div>
              )}
            </Level>
          ))}
        </LevelList>
      </Card>
    </div>
  );
};

export default VictoryConditionCard;
