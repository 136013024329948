import React, { useState } from "react";
import SpecialBuildingCard from "./SpecialBuildingCard";

const UsageSpecialBuildingCards = ({
  specialBuildingCards,
  onCardClick,
  player,
}) => {
  return (
    <div
      style={{
        display: "inline-flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
      }}
    >
      {specialBuildingCards.map((card, i) => (
        <SpecialBuildingCard
          card={card}
          player={player}
          onClick={() => onCardClick(i)}
        ></SpecialBuildingCard>
      ))}
    </div>
  );
};

export default UsageSpecialBuildingCards;
