import React from "react";
import holzIcon from "../assets/RohstoffHolz.png";
import steinIcon from "../assets/RohstoffStein.png";
import erzIcon from "../assets/RohstoffErz.png";
import nahrungIcon from "../assets/RohstoffNahrung2.png";

interface Props {
  resources: any;
}

const resourcesEnum = ["holz", "stein", "erz", "nahrung"];
const iconsEnum = [holzIcon, steinIcon, erzIcon, nahrungIcon];

const ResourcesInline = ({ resources }: Props) => (
  <div>
    <div>
      {resourcesEnum.map(
        (resource, i) =>
          resources[resource] > 0 && (
            <div
              style={{
                height: "24px",
                marginBottom: "5px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  display: "inline-block",
                  marginRight: "5px",
                  fontSize: "1.5rem",
                  lineHeight: "24px",
                  height: "24px",
                }}
              >
                {resources[resource]}
              </div>

              <img
                style={{ width: "24px", height: "24px" }}
                src={iconsEnum[i]}
              ></img>
            </div>
          )
      )}
    </div>
  </div>
);

export default ResourcesInline;
