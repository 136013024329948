import React from "react";
import { BuildingType } from "../shared";
import ResourcesInline from "./ResourcesInline";
import {
  faFileExcel,
  faAnchor,
  IconDefinition,
  faShip,
  faPlusCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Colors } from "../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  building: BuildingType;
}
const BuildingInformation = ({ building }: Props) => {
  let icon: IconDefinition | null = null;
  if (building.name === "Werft") icon = faAnchor;
  if (building.name === "Schiff") icon = faShip;
  if (building.name === "Lagererweiterung") icon = faPlusCircle;

  return (
    <div
      style={{
        width: "300px",
        margin: "10px",
        color: "black",
        padding: "20px",
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "white",
          opacity: "0.7",
          zIndex: -1,
          borderRadius: 9,
        }}
      ></div>
      <div style={{ fontSize: "1.3rem", marginBottom: "6px" }}>
        {icon && (
          <span style={{ marginRight: "8px" }}>
            <FontAwesomeIcon icon={icon}></FontAwesomeIcon>
          </span>
        )}
        {building.name}
      </div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {" "}
        <div>
          Kosten:
          {<ResourcesInline resources={building.cost}></ResourcesInline>}
        </div>
        <div>
          {building.provides && (
            <div>
              Produziert:
              {
                <ResourcesInline
                  resources={building.provides}
                ></ResourcesInline>
              }
            </div>
          )}
          {building.description && (
            <div style={{ marginLeft: "10px" }}>{building.description}</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default BuildingInformation;
