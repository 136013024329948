import React from "react";
import { GamestateType, PlayerType } from "../shared";
import { returnMerchantCard, returnSpecialBuildingCard } from "../api/game";
import SpecialBuildingCard from "./SpecialBuildingCard";
import MerchantCard from "./MerchantCard";
import { faCheck, faTrash } from "@fortawesome/free-solid-svg-icons";

interface Props {
  gamestate: GamestateType;
  players: PlayerType[];
  myPlayername: string;
}

const CardsToChoose = ({ gamestate, players, myPlayername }: Props) => {
  const myPlayer = () => players.find((p) => p.name === myPlayername)!;

  return (
    <>
      {gamestate.state === "specialphase" &&
        !myPlayer().finishedRound &&
        myPlayer().specialphase === "chooseMerchantCards" && (
          <span>
            Du hast neue Händlerkarten gezogen. Bitte gebe noch{" "}
            {myPlayer().drawMerchantCards} Karten zurück.
            <div
              style={{
                marginTop: "10px",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              {myPlayer().merchantCards.map((card, i) => (
                <MerchantCard
                  card={card}
                  onClick={() => returnMerchantCard(i)}
                  overlay={faTrash}
                />
              ))}
            </div>
          </span>
        )}

      {gamestate.state === "specialphase" &&
        !myPlayer().finishedRound &&
        myPlayer().specialphase === "chooseSpecialBuildingCards" && (
          <span>
            Du hast neue Spezialgebäude gezogen. Bitte gebe noch{" "}
            {myPlayer().drawSpecialBuildingCards} Karten zurück.
            <div
              style={{
                marginTop: "10px",

                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              {myPlayer().specialBuildingCards.map((card, i) => (
                <SpecialBuildingCard
                  card={card}
                  onClick={() => returnSpecialBuildingCard(i)}
                  overlay={faTrash}
                />
              ))}
            </div>
          </span>
        )}
    </>
  );
};

export default CardsToChoose;
