import React, { useState } from "react";
import { PlayerType, GamestateType, SpecialphasesEnum } from "../shared";
import GameTopBar from "../components/GameTopBar";
import styled from "styled-components";
import IslandsList from "../components/IslandsList";
import GameBottomBar from "../components/GameBottomBar";
import Dialog from "../components/Dialog";
import ContextActions from "../components/ContextActions";
import { handleTilePressed } from "../components/IslandUtils";
import {
  submitRound,
  buildSpecialCard,
  trade,
  chooseTerraforming,
} from "../api/game";
import CardsToChoose from "../components/CardsToChoose";
import HandCards from "../components/Handcards";
import UsageMerchantCards from "../components/UsageMerchantCards";
import UsageSpecialBuildingCards from "../components/UsageSpecialBuildingCards";
import Button from "../components/Button";
import GameFinishedDialog from "../components/GameFinishedDialog";
import OverviewVictoryConditions from "../components/OverviewVictoryConditions";
import DialogHeader from "../components/DialogHeader";
import {
  faArrowCircleLeft,
  faHourglassHalf,
} from "@fortawesome/free-solid-svg-icons";
import { Colors } from "../theme";
import ViewOpponentsSpecialBuildingCards from "../components/ViewOpponentsSpecialBuildingCards";
import { ActionSheetOutSide, ActionSheet } from "../components/ActionSheet";
import TransformDialog from "../components/TransformDialog";
import { leaveRunningGame } from "../api/rooms";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
interface Props {
  gamestate: GamestateType;
  players: PlayerType[];
  myRoom: string | null;
  myPlayername: string;
  ap: number;
}

const StyledContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const GameArea = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
`;

const PinnedMessage = styled.div`
  border-top: 2px solid ${Colors.color1};
  padding: 8px;
  background-color: ${Colors.color2};
  color: white;
  font-size: 1.8rem;
  text-align: center;
`;

const GamePage = ({ gamestate, players, myRoom, myPlayername, ap }: Props) => {
  const [contextAction, setContextAction] = useState<any | null>(null);
  const [showMerchantCardsUsage, setShowMerchantCardsUsage] = useState(false);
  const [showVictoryCondtions, setShowVictoryCondtions] = useState(false);
  const [showGameOptions, setShowGameOptions] = useState(false);

  const [
    showSpecialBuildingCardsUsage,
    setShowSpecialBuildingCardsUsage,
  ] = useState(false);
  const [
    showOpponentsSpecialBuildingCards,
    setShowOpponentsSpecialBuildingCards,
  ] = useState<string | null>(null);

  const myPlayer = () => players.find((p) => p.name === myPlayername);

  const onMerchantCardsHand = () => {
    setContextAction(null);
    setShowMerchantCardsUsage(true);
    setShowSpecialBuildingCardsUsage(false);
    setShowVictoryCondtions(false);
    setShowOpponentsSpecialBuildingCards(null);
  };

  const onSpecialBuildingCardsHand = () => {
    setContextAction(null);
    setShowSpecialBuildingCardsUsage(true);
    setShowMerchantCardsUsage(false);
    setShowVictoryCondtions(false);
    setShowOpponentsSpecialBuildingCards(null);
  };

  const onVictoryConditionsClick = () => {
    setContextAction(null);
    setShowSpecialBuildingCardsUsage(false);
    setShowMerchantCardsUsage(false);
    setShowVictoryCondtions(true);
    setShowOpponentsSpecialBuildingCards(null);
  };

  const onOpponentCardsClick = (color: string | null) => {
    setContextAction(null);
    setShowSpecialBuildingCardsUsage(false);
    setShowMerchantCardsUsage(false);
    setShowVictoryCondtions(false);
    setShowOpponentsSpecialBuildingCards(color);
  };

  const tilePressed = (islandindex: number, tileindex: number) => {
    handleTilePressed(
      gamestate,
      players,
      myPlayername,
      islandindex,
      tileindex,
      contextAction,
      setContextAction
    );
  };

  return (
    <StyledContainer>
      <GameTopBar
        players={players}
        gamestate={gamestate}
        ap={ap}
        myPlayername={myPlayername}
        onOpponentCardsClick={onOpponentCardsClick}
      >
        {gamestate.state === "pickLager" &&
          players &&
          players[gamestate.pickLagerPlayer] &&
          players[gamestate.pickLagerPlayer].name === myPlayername && (
            <PinnedMessage>
              {gamestate.pickLagerRound === 1
                ? "Bitte wähle einen ersten möglichen Startplatz"
                : "Bitte wähle einen zweiten möglichen Startplatz"}
            </PinnedMessage>
          )}
        {gamestate.state === "chooseFreeBuilding" &&
          players.find((p) => p.name === myPlayername) &&
          !players.find((p) => p.name === myPlayername)!.finishedRound && (
            <PinnedMessage>
              Bitte wähle ein kostenloses Rohstoffgebäude auf deiner Startinsel
            </PinnedMessage>
          )}
        {gamestate.state === "specialphase" &&
          players.find((p) => p.name === myPlayername) &&
          !players.find((p) => p.name === myPlayername)!.finishedRound &&
          players.find((p) => p.name === myPlayername)?.specialphase ===
            SpecialphasesEnum.ShipToLand && (
            <PinnedMessage>
              Bitte platziere dein Lager auf einer neuen Insel
            </PinnedMessage>
          )}
      </GameTopBar>
      <GameArea>
        <div style={{ overflowY: "auto", flex: 1 }}>
          <IslandsList
            islands={gamestate.islands}
            tilePressed={tilePressed}
          ></IslandsList>
        </div>
        {contextAction && (
          <>
            <ActionSheet>
              <ActionSheetOutSide
                onClick={() => setContextAction(null)}
              ></ActionSheetOutSide>
              <Dialog style={{ zIndex: 102 }}>
                <div style={{ padding: "0 20px" }}>
                  <ContextActions
                    contextAction={contextAction}
                    setContextAction={setContextAction}
                    playername={myPlayername}
                  ></ContextActions>
                </div>
              </Dialog>
            </ActionSheet>
          </>
        )}

        {myPlayer() &&
          gamestate.state === "specialphase" &&
          !myPlayer()!.finishedRound &&
          myPlayer()!.specialphase === SpecialphasesEnum.Terraform && (
            <TransformDialog />
          )}

        {myPlayer() &&
          gamestate.state === "specialphase" &&
          !myPlayer()!.finishedRound &&
          (myPlayer()!.specialphase === "chooseMerchantCards" ||
            myPlayer()!.specialphase === "chooseSpecialBuildingCards") && (
            <>
              <ActionSheetOutSide
                onClick={() => setContextAction(null)}
              ></ActionSheetOutSide>{" "}
              <ActionSheet>
                <Dialog style={{ zIndex: 102 }}>
                  <CardsToChoose
                    gamestate={gamestate}
                    players={players}
                    myPlayername={myPlayername}
                  ></CardsToChoose>
                </Dialog>
              </ActionSheet>
            </>
          )}

        {showMerchantCardsUsage && (
          <>
            <ActionSheetOutSide
              onClick={() => setContextAction(null)}
            ></ActionSheetOutSide>
            <ActionSheet>
              <Dialog style={{ zIndex: 102 }}>
                <UsageMerchantCards
                  merchantCards={myPlayer()!.merchantCards}
                  onCardClick={(
                    cardindex: number,
                    tradeindex: number,
                    times: number
                  ) => {
                    trade(cardindex, tradeindex, times);
                    setShowMerchantCardsUsage(false);
                  }}
                  resources={myPlayer()!.resources}
                />
                <Button
                  onClick={() => setShowMerchantCardsUsage(false)}
                  title="Keine Karte nutzen"
                ></Button>
              </Dialog>
            </ActionSheet>
          </>
        )}

        {showSpecialBuildingCardsUsage && (
          <>
            <ActionSheetOutSide></ActionSheetOutSide>
            <ActionSheet>
              <Dialog style={{ zIndex: 102 }}>
                <UsageSpecialBuildingCards
                  specialBuildingCards={myPlayer()!.specialBuildingCards}
                  onCardClick={(cardindex: number) => {
                    buildSpecialCard(cardindex);
                    setShowSpecialBuildingCardsUsage(false);
                  }}
                  player={myPlayer()!}
                />
                <Button
                  onClick={() => {
                    buildSpecialCard(null);
                    setShowSpecialBuildingCardsUsage(false);
                  }}
                  title="Keine Karte nutzen"
                ></Button>
              </Dialog>
            </ActionSheet>
          </>
        )}
        {showOpponentsSpecialBuildingCards && (
          <ViewOpponentsSpecialBuildingCards
            players={players}
            playerColor={showOpponentsSpecialBuildingCards}
            onCardClick={() => setShowOpponentsSpecialBuildingCards(null)}
            onBackClick={() => setShowOpponentsSpecialBuildingCards(null)}
            myPlayerColor={myPlayer()?.color || ""}
          />
        )}

        {showVictoryCondtions && (
          <>
            <ActionSheetOutSide></ActionSheetOutSide>

            <ActionSheet>
              <Dialog style={{ zIndex: 102 }}>
                <DialogHeader
                  title="Siegbedingungen"
                  iconLeft={faArrowCircleLeft}
                  onIconLeftClick={() => setShowVictoryCondtions(false)}
                ></DialogHeader>
                <OverviewVictoryConditions
                  victoryConditions={gamestate.victoryConditions}
                />
              </Dialog>
            </ActionSheet>
          </>
        )}

        {gamestate && gamestate.started && myPlayername && gamestate.finished && (
          <>
            <ActionSheetOutSide
              onClick={() => setContextAction(null)}
            ></ActionSheetOutSide>
            <ActionSheet>
              <Dialog style={{ zIndex: 102 }}>
                <GameFinishedDialog
                  gamestate={gamestate}
                  players={players}
                ></GameFinishedDialog>
              </Dialog>
            </ActionSheet>
          </>
        )}

        {showGameOptions && (
          <>
            <ActionSheetOutSide
              onClick={() => setShowGameOptions(false)}
            ></ActionSheetOutSide>
            <ActionSheet>
              <Dialog style={{ zIndex: 102 }}>
                <Button
                  style={{ marginBottom: "20px" }}
                  onClick={() => setShowGameOptions(false)}
                  title="Zurück zum Spiel"
                ></Button>
                <Button
                  onClick={leaveRunningGame}
                  title="Aufgeben und Spiel verlassen"
                ></Button>
              </Dialog>
            </ActionSheet>
          </>
        )}

        {myPlayer() && myPlayer()!.missATurn && !myPlayer()!.finishedRound && (
          <>
            <ActionSheetOutSide></ActionSheetOutSide>

            <ActionSheet>
              <Dialog style={{ zIndex: 102 }}>
                <DialogHeader title="Nahrungsmangel"></DialogHeader>
                Aufgrund der mangelnden Nahrung haben deine Leute nicht genug
                Kraft zum Arbeiten. Setze daher eine Runde aus.
                <div style={{ margin: "15px" }}>
                  <FontAwesomeIcon
                    icon={faHourglassHalf}
                    size={"2x"}
                  ></FontAwesomeIcon>
                </div>
                <Button onClick={submitRound} title="Weiter" />
              </Dialog>
            </ActionSheet>
          </>
        )}
      </GameArea>
      {myPlayer() && (
        <HandCards
          merchantCards={myPlayer()!.merchantCards}
          onMerchantsClick={onMerchantCardsHand}
          specialBuildingCards={myPlayer()!.specialBuildingCards}
          onSpecialBuildingsClick={onSpecialBuildingCardsHand}
        />
      )}

      <GameBottomBar
        victoryConditions={gamestate.victoryConditions}
        onCardsClick={() => onVictoryConditionsClick()}
        onShowOptions={() => setShowGameOptions(!showGameOptions)}
      ></GameBottomBar>
    </StyledContainer>
  );
};

export default GamePage;
