import React from "react";
import IslandTile from "./IslandTile";

const Island = ({ island, onTilePressed, activeTile }: any) => {
  const islandHasLager =
    island.filter((tile: any) => tile.lagerListe.length > 0).length > 0;
  return (
    <div style={{ margin: "10px", display: "inline-block" }}>
      <div>
        <IslandTile
          tile={island[0]}
          onPress={() => onTilePressed(0)}
          active={activeTile === 0}
        ></IslandTile>
        <IslandTile
          tile={island[1]}
          onPress={() => onTilePressed(1)}
          active={activeTile === 1}
        ></IslandTile>
      </div>
      <div>
        <IslandTile
          tile={island[2]}
          onPress={() => onTilePressed(2)}
          active={activeTile === 2}
        ></IslandTile>
        <IslandTile
          tile={island[3]}
          onPress={() => onTilePressed(3)}
          active={activeTile === 3}
          hidden={!islandHasLager}
        ></IslandTile>
        <IslandTile
          tile={island[4]}
          onPress={() => onTilePressed(4)}
          active={activeTile === 4}
        ></IslandTile>
      </div>
      <div>
        <IslandTile
          tile={island[5]}
          onPress={() => onTilePressed(5)}
          active={activeTile === 5}
        ></IslandTile>
        <IslandTile
          tile={island[6]}
          onPress={() => onTilePressed(6)}
          active={activeTile === 6}
        ></IslandTile>
      </div>
    </div>
  );
};

export default Island;
