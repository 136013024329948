import React from "react";
import { PlayerType, GamestateType, GamephaseEnum } from "../shared";
import styled from "styled-components";
import PlayerBox from "./PlayerBox";
import { Colors } from "../theme";
import MerchantCardDeck from "./MerchantCardDeck";
import SpecialBuildingsDeck from "./SpecialBuildingsDeck";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import {
  drawMerchantCard,
  drawSpecialBuildingCard,
  submitRound,
  undoSubmitRound,
} from "../api/game";
import PacmanLoader from "react-spinners/PacmanLoader";
interface Props {
  gamestate: GamestateType;
  players: PlayerType[];
  ap: number;
  onOpponentCardsClick: Function;
  myPlayername: string;
  children?: React.ReactNode;
}

const Container = styled.div`
  background-color: ${Colors.color2};
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.75);
  border-bottom: 2px solid ${Colors.color1};
  z-index: 10;
`;

const InnerContainer = styled.div`
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const IconButton = styled.div`
  /* background-color: ${Colors.color3}; */
  margin: 10px;
  padding: 5px 0;
  text-align: center;
  vertical-align: center;
`;

const LeftBox = styled.div`
  align-self: stretch;
  min-width: 144px;
  max-width: 144px;
  background-color: ${Colors.color1};
  color: white;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const PlayersArea = styled.div`
  display: flex;
  overflow-x: auto;
`;

const RightBox = styled.div`
  align-self: stretch;
  min-width: 144px;
  background-color: ${Colors.color1};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const GameTopBar = ({
  gamestate,
  players,
  ap,
  myPlayername,
  onOpponentCardsClick,
  children,
}: Props) => {
  const myPlayer = () => players.find((p) => p.name === myPlayername);

  return (
    <Container style={{}}>
      <InnerContainer>
        <LeftBox>
          {gamestate.finished && (
            <div
              style={{
                marginTop: "20px",
                fontSize: "20px",
                lineHeight: "22px",
              }}
            >
              Spielende!
            </div>
          )}

          {myPlayer()?.finishedRound && !gamestate.finished ? (
            <div
              onClick={
                gamestate.state === GamephaseEnum.Gameround &&
                !myPlayer()!.missATurn
                  ? undoSubmitRound
                  : () => {}
              }
              style={{
                fontSize: "20px",
                lineHeight: "22px",
              }}
            >
              Warte
              <br /> auf Mitspieler
              <div
                style={{
                  marginTop: "10px",
                  marginLeft: "15px",
                  height: "50px",
                }}
              >
                <PacmanLoader size={25} color={"#ffffff"}></PacmanLoader>
              </div>
            </div>
          ) : (
            gamestate.state === GamephaseEnum.Gameround && (
              <>
                <div>Runde {gamestate.round}</div>

                {myPlayer() && myPlayer()!.missATurn ? (
                  <div style={{ fontSize: "1.4rem" }}>
                    Du setzt diese Runde aus.
                  </div>
                ) : ap === 5 ? (
                  <IconButton onClick={submitRound}>
                    <FontAwesomeIcon
                      icon={faArrowCircleRight}
                      size={"3x"}
                    ></FontAwesomeIcon>
                  </IconButton>
                ) : (
                  <div style={{ fontSize: "1.8rem" }}>{ap} / 5 AP</div>
                )}
              </>
            )
          )}
        </LeftBox>
        <PlayersArea>
          {players.map((player) => (
            <PlayerBox
              key={player.name}
              player={player}
              isSpecialphase={gamestate.state === GamephaseEnum.Specialphase}
              onOpponentCardsClick={() => onOpponentCardsClick(player.color)}
            ></PlayerBox>
          ))}
        </PlayersArea>
        <RightBox>
          <MerchantCardDeck
            onClick={
              gamestate.state === GamephaseEnum.Gameround &&
              myPlayer() &&
              !myPlayer()!.missATurn
                ? drawMerchantCard
                : () => {}
            }
          >
            {myPlayer() && myPlayer()!.drawMerchantCards > 0
              ? myPlayer()!.drawMerchantCards.toString()
              : " "}
          </MerchantCardDeck>
          <SpecialBuildingsDeck
            onClick={
              gamestate.state === GamephaseEnum.Gameround &&
              myPlayer() &&
              !myPlayer()!.missATurn
                ? drawSpecialBuildingCard
                : () => {}
            }
          >
            {myPlayer() && myPlayer()!.drawSpecialBuildingCards > 0
              ? myPlayer()!.drawSpecialBuildingCards.toString()
              : " "}
          </SpecialBuildingsDeck>
        </RightBox>
      </InnerContainer>

      {children && <div>{children}</div>}
    </Container>
  );
};

export default GameTopBar;
