import { canPay, getBuildingForField, isConnected } from "../shared/utils";
import {
  FieldType,
  FieldEnum,
  GamestateType,
  PlayerType,
  SpecialBuildingCategoryEnum,
} from "../shared";
import { Schiff, Lagererweiterung, Werft } from "../shared/buildings";
import socket from "../api/socket";

export const handleTilePressed = (
  gamestate: GamestateType,
  players: PlayerType[],
  myPlayername: string,
  island: number,
  field: number,
  contextAction: any,
  setContextAction: Function
) => {
  if (!myPlayername) return;
  const myPlayer = players.find((p) => p.name === myPlayername);
  if (!myPlayer) return;

  const isMyIsland = (island: number) =>
    gamestate.islands[island].filter((field: FieldType) =>
      field.lagerListe.includes(
        players.find((p) => p.name === myPlayername)!.color
      )
    ).length > 0;

  const f = gamestate.islands[island][field];
  if (
    gamestate.state === "pickLager" &&
    players[gamestate.pickLagerPlayer].name === myPlayername &&
    field !== 3 &&
    f.terrain !== 4 && // kein wasserfeld
    f.bauListe.length === 0
  ) {
    socket.emit("pick", { island, field });
  }
  if (
    gamestate.state === "chooseFreeBuilding" &&
    !myPlayer.finishedRound &&
    gamestate.islands[island].filter((field: FieldType) =>
      field.lagerListe.includes(myPlayer.color)
    ).length > 0 &&
    !f.lagerListe.includes(myPlayer.color) &&
    f.terrain !== FieldEnum.Gras
  ) {
    socket.emit("freeBuilding", { island, field });
  }
  if (
    gamestate.state === "specialphase" &&
    !myPlayer.finishedRound &&
    myPlayer.specialphase === "shipToLand" &&
    myPlayer.shipToLand &&
    field !== 3 && // Nicht das Mittelfeld
    f.terrain !== FieldEnum.Wasser && // kein wasserfeld
    gamestate.islands[island].filter((field) =>
      field.lagerListe.includes(myPlayer.color)
    ).length === 0
  ) {
    socket.emit("landShip", { island, field });
  }
  if (gamestate.state === "gameround" && !myPlayer.finishedRound) {
    if (
      contextAction !== null &&
      contextAction.island === island &&
      contextAction.field === field
    ) {
      setContextAction(null);
      return;
    }
    const build =
      !f.lagerListe.includes(myPlayer.color) &&
      !f.bauListe.includes(myPlayer.color) &&
      !f.gebaeudeListe.includes(myPlayer.color) &&
      isMyIsland(island) &&
      f.terrain !== FieldEnum.Gras &&
      getBuildingForField(f) !== null &&
      canPay(getBuildingForField(f)!.cost, myPlayer.resources);

    const lagerTileIndex = gamestate.islands[island].findIndex((field) =>
      field.lagerListe.includes(myPlayer.color)
    );
    const sammeln =
      !f.sammlerListe.includes(myPlayer.color) &&
      isMyIsland(island) &&
      f.terrain !== FieldEnum.Gras &&
      f.terrain !== FieldEnum.Erz;

    const doppeltSammeln =
      !f.sammlerListe.includes(myPlayer.color) &&
      isMyIsland(island) &&
      f.terrain !== FieldEnum.Gras &&
      f.terrain !== FieldEnum.Erz &&
      (gamestate.islands[island].findIndex((field) =>
        field.lagerListe.includes(myPlayer.color)
      ) === field ||
        isConnected(
          gamestate.islands[island].findIndex((field) =>
            field.lagerListe.includes(myPlayer.color)
          ),
          field
        ));

    const traeger =
      !f.lagerListe.includes(myPlayer.color) &&
      !f.traegerListe.includes(myPlayer.color) &&
      isMyIsland(island);

    const transform =
      f.terrain === FieldEnum.Gras &&
      isMyIsland(island) &&
      f.lagerListe.length === 0 &&
      f.transformListe.length === 0;

    const clearPlan =
      f.traegerListe.includes(myPlayer.color) ||
      f.sammlerListe.includes(myPlayer.color) ||
      f.transformListe.includes(myPlayer.color) ||
      f.bauListe.includes(myPlayer.color);

    const hafen =
      lagerTileIndex === field &&
      !f.bauListe.includes(myPlayer.color) &&
      !f.hafenListe.includes(myPlayer.color) &&
      canPay(Werft.cost, myPlayer.resources);

    const buildLagererweiterung =
      lagerTileIndex === field &&
      !myPlayer.increaseStorage &&
      myPlayer.currentLager < 3 &&
      canPay(Lagererweiterung.cost, myPlayer.resources);

    const SchiffBuilding = myPlayer.specialBuildings.find(
      (b) =>
        b.category === SpecialBuildingCategoryEnum.Unique &&
        b.changesCost?.name === Schiff.name
    );
    const schiffCost =
      !!SchiffBuilding &&
      SchiffBuilding.category === SpecialBuildingCategoryEnum.Unique &&
      !!SchiffBuilding.changedCost
        ? SchiffBuilding.changedCost
        : Schiff.cost;

    const schiff =
      lagerTileIndex === field &&
      f.hafenListe.includes(myPlayer.color) &&
      !f.bauListe.includes(myPlayer.color) &&
      canPay(schiffCost, myPlayer.resources);

    if (
      build ||
      sammeln ||
      doppeltSammeln ||
      traeger ||
      clearPlan ||
      hafen ||
      buildLagererweiterung ||
      schiff ||
      transform
    ) {
      setContextAction({
        build,
        sammeln,
        doppeltSammeln,
        traeger,
        clearPlan,
        island,
        field,
        hafen,
        buildLagererweiterung,
        schiff,
        transform,
      });
    } else {
      setContextAction(null);
    }
  }
};
