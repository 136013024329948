import React, { useState } from "react";
import Button from "./Button";
import Dialog from "./Dialog";
import { GameRoomType } from "../shared";
import styled from "styled-components";
import DialogHeader from "./DialogHeader";
import {
  faPlusCircle,
  faLock,
  faArrowCircleLeft,
} from "@fortawesome/free-solid-svg-icons";
import { Colors } from "../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Input from "./Input";

interface Props {
  games: GameRoomType[];
  addGame: Function;
  joinGameLobby: Function;
}

const GameRow = styled.div`
  display: flex;
  background-color: ${Colors.color3};
  font-size: 20px;
  padding: 10px 0;
  margin-top: 8px;
  border-radius: 4px;
`;

const GameRowTitle = styled.div`
  flex: 1;
`;
const GameRowLock = styled.div`
  width: 50px;
`;

const GameRowCount = styled.div`
  width: 50px;
`;

const RoomList = ({ games, joinGameLobby, addGame }: Props) => {
  const [password, setPassword] = useState<string>("");
  const [joinInProgress, setJoinInProgress] = useState<string | null>(null);
  return (
    <>
      {!joinInProgress && (
        <Dialog style={{ textAlign: "center" }}>
          <DialogHeader
            title="Spiel wählen"
            iconRight={faPlusCircle}
            onIconRightClick={() => addGame()}
          ></DialogHeader>
          {games.length > 0 ? (
            <div>
              {games.map((game) => (
                <GameRow
                  onClick={() =>
                    game.password
                      ? setJoinInProgress(game.id)
                      : joinGameLobby(game.id, null)
                  }
                >
                  <GameRowLock>
                    {game.password && (
                      <FontAwesomeIcon icon={faLock}></FontAwesomeIcon>
                    )}
                  </GameRowLock>
                  <GameRowTitle>{game.title}</GameRowTitle>

                  <GameRowCount>
                    {game.playersCount + "/" + game.maxPlayer}
                  </GameRowCount>
                </GameRow>
              ))}
            </div>
          ) : (
            <p>Keine Spiele gefunden...</p>
          )}
        </Dialog>
      )}
      {joinInProgress && (
        <Dialog style={{ textAlign: "center" }}>
          <DialogHeader
            title="Spiel beitreten"
            iconLeft={faArrowCircleLeft}
            onIconLeftClick={() => {
              setJoinInProgress(null);
              setPassword("");
            }}
          ></DialogHeader>
          <div style={{ margin: "10px" }}>
            <Input
              onChange={(event: any) => setPassword(event.target.value)}
              value={password}
              placeholder="Passwort?"
            ></Input>
          </div>
          <Button
            onClick={() => {
              joinGameLobby(joinInProgress, password);
              setJoinInProgress(null);
              setPassword("");
            }}
            title="Beitreten"
          ></Button>
        </Dialog>
      )}
    </>
  );
};

export default RoomList;
