import React from "react";
import { PlayerType } from "../shared";
import styled from "styled-components";
import Resourceboard from "./Resourceboard";
import { Colors } from "../theme";
import colorContrast from "color-contrast";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHammer } from "@fortawesome/free-solid-svg-icons";

interface Props {
  player: PlayerType;
  isSpecialphase: boolean;
  onOpponentCardsClick: Function;
}

const PlayerBox = ({ player, isSpecialphase, onOpponentCardsClick }: Props) => {
  const Container = styled.div`
    border-left: 2px solid ${Colors.color1};
    border-right: 2px solid ${Colors.color1};
    background-color: ${player.finishedRound ? Colors.color2 : "white"};
    opacity: ${player.connected ? 1 : 0.2};
  `;

  const textOnPlayerColor =
    colorContrast(player.color, "white") > colorContrast(player.color, "black")
      ? "white"
      : "black";
  return (
    <Container>
      <div
        style={{
          backgroundColor: player.color,
          borderBottom: "2px solid " + Colors.color1,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <span
          style={{
            padding: "0 10px",
            backgroundColor: "#ffa5ba",
            borderRight: "2px solid " + Colors.color1,
          }}
        >
          {player.merchantCards.length}
          {player.drawMerchantCards > 0
            ? (isSpecialphase ? " -" : " +") + player.drawMerchantCards
            : ""}
        </span>
        <div
          style={{
            padding: "0 4px",
            color: textOnPlayerColor,
          }}
        >
          {player.name}
        </div>
        <span
          style={{
            backgroundColor: "#fffb9b",
            padding: "0 10px",
            borderLeft: "2px solid " + Colors.color1,
          }}
        >
          {player.specialBuildingCards.length}
          {player.drawSpecialBuildingCards > 0
            ? (isSpecialphase ? " -" : " +") + player.drawSpecialBuildingCards
            : ""}
        </span>
      </div>
      <div
        style={{
          fontSize: "16px",
          color: player.finishedRound ? "white" : "black",
        }}
      >
        <Resourceboard
          resources={player.resources}
          maxLager={player.maxLager}
        ></Resourceboard>
        <div>
          {player.specialBuildings.length > 0 ? (
            <div
              style={{
                backgroundColor: "#fffb9b",
                padding: "4px",
                margin: "2px",
                color: "black",
                whiteSpace: "nowrap",
              }}
              onClick={() => onOpponentCardsClick()}
            >
              {player.specialBuildings[player.specialBuildings.length - 1].name}
              {player.specialBuildings.length > 1 &&
                ` + ${player.specialBuildings.length - 1}`}
              {player.buildSpecialBuilding !== null && (
                <span style={{ marginLeft: "10px" }}>
                  +{" "}
                  <FontAwesomeIcon
                    icon={faHammer}
                    size={"1x"}
                    color={"black"}
                  />
                </span>
              )}
            </div>
          ) : (
            <div
              style={{
                backgroundColor: "#fffb9b",
                padding: "4px",
                margin: "2px",
                color: "#fffb9b",
                opacity: player.buildSpecialBuilding !== null ? 1 : 0.3,
              }}
            >
              {player.buildSpecialBuilding !== null ? (
                <span style={{ marginLeft: "10px" }}>
                  <FontAwesomeIcon
                    icon={faHammer}
                    size={"1x"}
                    color={"black"}
                  />
                </span>
              ) : (
                "Gebäude"
              )}
            </div>
          )}
        </div>
      </div>
    </Container>
  );
};

export default PlayerBox;
