import React from "react";
import Island from "./Island";
import CostTable from "./CostTable";

interface Props {
  tilePressed: (islandIndex: number, tileIndex: number) => void;
  islands: any[];
}

const IslandsList = ({ islands, tilePressed }: Props) => {
  return (
    <div style={{ textAlign: "center", paddingBottom: "40px" }}>
      {islands.map((island, i) => (
        <Island
          key={i}
          island={island}
          onTilePressed={(tileIndex: number) => tilePressed(i, tileIndex)}
          activeTile={null}
        ></Island>
      ))}
      <div style={{ marginTop: "50px" }}>
        <CostTable />
      </div>
    </div>
  );
};

export default IslandsList;
