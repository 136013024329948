import React from "react";
import styled from "styled-components";
import { Colors } from "../theme";
import holzIcon from "../assets/RohstoffHolz.png";
import steinIcon from "../assets/RohstoffStein.png";
import erzIcon from "../assets/RohstoffErz.png";
import nahrungIcon from "../assets/RohstoffNahrung2.png";

interface Props {
  resources: any;
  maxLager: any;
}

const DotsContainer = styled.div`
  @media (max-width: 1400px) {
    display: none;
  }
`;

const resourcesEnum = ["holz", "stein", "erz", "nahrung"];
const iconsEnum = [holzIcon, steinIcon, erzIcon, nahrungIcon];

const Resourceboard = ({ resources, maxLager }: Props) => (
  <div
    style={{
      padding: "2px 8px",
      display: "flex",
      flexDirection: "row",
      justifyContent: "center",
    }}
  >
    <div>
      {resourcesEnum.map((resource, i) => (
        <div
          style={{ display: "flex", justifyContent: "space-between" }}
          key={resource}
        >
          <div style={{ textAlign: "right", height: "24px" }}>
            <img
              style={{ width: "24px", height: "24px" }}
              src={iconsEnum[i]}
            ></img>
          </div>
          <div
            style={{
              marginLeft: "10px",
              marginRight: "10px",
              whiteSpace: "nowrap",
            }}
          >
            {resources[resource] + " / " + maxLager[resource]}
          </div>
        </div>
      ))}
    </div>

    <DotsContainer>
      {resourcesEnum.map((resource) => (
        <div style={{ display: "flex", flexDirection: "row" }} key={resource}>
          {new Array(Math.min(maxLager[resource], 10))
            .fill(null)
            .map((e, i) => (
              <div
                style={
                  resources[resource] > i
                    ? {
                        width: "20px",
                        height: "24px",
                        border: "1px solid " + Colors.color3,
                        backgroundColor: Colors.color1,
                        color: "white",
                      }
                    : {
                        width: "20px",
                        height: "24px",
                        border: "1px solid " + Colors.color3,
                        backgroundColor: "white",
                      }
                }
                key={i}
              >
                {i === 9 && resources[resource] > 10 && "+"}
              </div>
            ))}
          {new Array(Math.max(10 - maxLager[resource], 0))
            .fill(null)
            .map((e, i) => (
              <div
                style={{
                  width: "20px",
                  height: "24px",
                  border: "1px solid " + Colors.color3,
                  backgroundColor: Colors.color3,
                }}
                key={i}
              ></div>
            ))}
        </div>
      ))}
    </DotsContainer>
  </div>
);

export default Resourceboard;
