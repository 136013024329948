import React, { useState } from "react";
import MerchantCard from "./MerchantCard";
import { VictoryConditionType } from "../shared/interfaces/VictoryConditionType";
import colorContrast from "color-contrast";
import VictoryConditionCard from "./VictoryConditionCard";

interface Props {
  victoryConditions: VictoryConditionType[];
}
const OverviewVictoryConditions = ({ victoryConditions }: Props) => {
  return (
    <div
      style={{
        display: "inline-flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
      }}
    >
      {victoryConditions.map((vc, i) => (
        <VictoryConditionCard victoryCondition={vc}></VictoryConditionCard>
      ))}
    </div>
  );
};

export default OverviewVictoryConditions;
