import React from "react";

import styled from "styled-components";
import { canPay } from "../shared/utils";
import ResourcesInline from "./ResourcesInline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Card = styled.div`
  background-color: #ffa5ba;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%23f06fed' fill-opacity='0.21' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
  width: 250px;
  height: 300px;
  text-align: center;
  display: inline-flex;
  border-radius: 9px;
  border: 1px solid gray;
  font-size: 20px;
  flex-direction: column;
  justify-content: space-around;
  margin: 5px;
  color: black;
  position: relative;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.2;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  color: red;
  :hover {
    opacity: 1;
  }
`;

const TakeDealButton = styled.div`
  margin-top: 8px;
  padding: 4px;
  margin: 4px;
  background-color: #e38198;
  border-radius: 2px;
  font-size: 1.1rem;

  display: inline-block;
`;

const MerchantCard = ({
  card,
  onClick,
  style,
  onDealTaken,
  resources,
  overlay,
}: any) => {
  const ownStyle = {};

  return (
    <div style={style} onClick={onClick}>
      <Card>
        {overlay && (
          <Overlay>
            <FontAwesomeIcon icon={overlay} size={"3x"}></FontAwesomeIcon>
          </Overlay>
        )}
        {card.trades.map((trade, i) => (
          <div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-around",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  flex: "2",
                }}
              >
                <ResourcesInline resources={trade.tradein}></ResourcesInline>
              </div>
              <div>--></div>
              <div
                style={{
                  flex: "2",
                }}
              >
                <ResourcesInline resources={trade.provides}></ResourcesInline>
              </div>
            </div>
            {resources &&
              [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(
                (times) =>
                  canPay(
                    {
                      holz: trade.tradein.holz * times,
                      stein: trade.tradein.stein * times,
                      erz: trade.tradein.erz * times,
                      nahrung: trade.tradein.nahrung * times,
                    },
                    resources
                  ) && (
                    <TakeDealButton onClick={() => onDealTaken(i, times)}>
                      {times + "x"}
                    </TakeDealButton>
                  )
              )}
          </div>
        ))}
      </Card>
    </div>
  );
};

export default MerchantCard;
