import React from "react";
import { BuildingType } from "../shared";
import ResourcesInline from "./ResourcesInline";
import { faFileExcel, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { Colors } from "../theme";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface Props {
  icon: IconDefinition;
  iconColor: string;
  title: string;
  description: string;
  ap: string;
}
const ActionInformation = ({
  icon,
  iconColor,
  title,
  description,
  ap,
}: Props) => (
  <div
    style={{
      width: "300px",
      margin: "10px",
      color: "black",
      padding: "20px",
      position: "relative",
    }}
  >
    <div
      style={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        backgroundColor: "white",
        opacity: "0.7",
        zIndex: -1,
        borderRadius: 9,
      }}
    ></div>
    <div style={{ fontSize: "1.3rem", marginBottom: "6px" }}>{title}</div>
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div>
        <FontAwesomeIcon
          icon={icon}
          color={iconColor}
          size={"3x"}
        ></FontAwesomeIcon>
      </div>
      <div style={{ marginLeft: "10px" }}>
        <div>
          <b>
            {ap} {ap === "1" ? "Aktionspunkt" : "Aktionspunkte"}
          </b>
        </div>
        {description}
      </div>
    </div>
  </div>
);

export default ActionInformation;
