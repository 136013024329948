import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface Props {
  title: string;
  iconLeft?: IconProp;
  onIconLeftClick?: Function;
  iconRight?: IconProp;
  onIconRightClick?: Function;
}

const iconStyle = { fontSize: "25px", margin: "2px" };
const iconPlaceholder = { width: "25px", height: "25px", margin: "2px" };

const DialogHeader = ({
  title,
  iconLeft,
  onIconLeftClick,
  iconRight,
  onIconRightClick,
}: Props) => {
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      {iconLeft && onIconLeftClick ? (
        <FontAwesomeIcon
          onClick={() => onIconLeftClick()}
          icon={iconLeft}
          style={iconStyle}
          color={"white"}
        />
      ) : (
        <div style={iconPlaceholder}></div>
      )}
      <div>{title}</div>
      {iconRight && onIconRightClick ? (
        <FontAwesomeIcon
          onClick={() => onIconRightClick()}
          icon={iconRight}
          style={iconStyle}
          color={"white"}
        />
      ) : (
        <div style={iconPlaceholder}></div>
      )}
    </div>
  );
};

export default DialogHeader;
