import { BuildingType } from "./interfaces";

export const Holzfällerhütte: BuildingType = {
  name: "Holzfällerhütte",
  cost: {
    holz: 3,
    stein: 1,
    erz: 0,
    nahrung: 0,
  },
  provides: {
    holz: 2,
    stein: 0,
    erz: 0,
    nahrung: 0,
  },
};

export const Steinbruch: BuildingType = {
  name: "Steinbruch",
  cost: {
    holz: 1,
    stein: 4,
    erz: 0,
    nahrung: 0,
  },
  provides: {
    holz: 0,
    stein: 2,
    erz: 0,
    nahrung: 0,
  },
};
export const Fischerhütte: BuildingType = {
  name: "Fischerhütte",
  cost: {
    holz: 4,
    stein: 1,
    erz: 0,
    nahrung: 0,
  },
  provides: {
    holz: 0,
    stein: 0,
    erz: 0,
    nahrung: 2,
  },
};
export const Bauernhof: BuildingType = {
  name: "Bauernhof",
  cost: {
    holz: 4,
    stein: 4,
    erz: 0,
    nahrung: 0,
  },
  provides: {
    holz: 0,
    stein: 0,
    erz: 0,
    nahrung: 3,
  },
};

export const Erzmine: BuildingType = {
  name: "Erzmine",
  cost: {
    holz: 4,
    stein: 3,
    erz: 0,
    nahrung: 0,
  },
  provides: {
    holz: 0,
    stein: 0,
    erz: 1,
    nahrung: 0,
  },
};

export const Lagererweiterung: BuildingType = {
  name: "Lagererweiterung",
  description: "Drei Stufen: 2 | 2 | 1 zusätzliche Lagerplätze je Rohstoff",
  cost: {
    holz: 2,
    stein: 2,
    erz: 2,
    nahrung: 0,
  },
  store1: {
    holz: 2,
    stein: 2,
    erz: 2,
    nahrung: 2,
  },
  store2: {
    holz: 2,
    stein: 2,
    erz: 2,
    nahrung: 2,
  },
  store3: {
    holz: 1,
    stein: 1,
    erz: 1,
    nahrung: 1,
  },
};
export const Werft: BuildingType = {
  name: "Werft",
  description: "Benötigt für die Schifffahrt",
  cost: {
    holz: 2,
    stein: 5,
    erz: 1,
    nahrung: 0,
  },
};

export const Schiff: BuildingType = {
  name: "Schiff",
  description: "Eine neue Insel mit einem Lager besiedeln",
  cost: {
    holz: 7,
    stein: 0,
    erz: 0,
    nahrung: 5,
  },
};
