import React from "react";
import { PlayerType, GamestateType, GamephaseEnum } from "../shared";
import styled from "styled-components";
import PlayerBox from "./PlayerBox";
import { Colors } from "../theme";
import MerchantCardDeck from "./MerchantCardDeck";
import SpecialBuildingsDeck from "./SpecialBuildingsDeck";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import { drawMerchantCard, drawSpecialBuildingCard } from "../api/game";
import PacmanLoader from "react-spinners/PacmanLoader";
import { findPlayerByColor } from "../shared/utils";
import colorContrast from "color-contrast";
import Button from "./Button";
import { leaveRunningGame } from "../api/rooms";

interface Props {
  gamestate: GamestateType;
  players: PlayerType[];
}

const StyledContainer = styled.div``;
const Round = styled.div`
  font-size: 1rem;
`;

const GameFinishedDialog = ({ gamestate, players }: Props) => {
  const winners = players
    .map((p) => ({
      color: p.color,
      vps: gamestate.victoryConditions.filter((vc) =>
        vc.playersFulfillingMaxLevel.includes(p.color)
      ).length,
    }))
    .filter((item) => item.vps >= 3)
    .map((item) => item.color);
  return (
    <StyledContainer>
      <Round>Runde {gamestate.round}</Round>
      <p>Spielende!</p>
      Glückwunsch an:
      {winners.map((c) => {
        const p = findPlayerByColor(players, c);
        return (
          p && (
            <div
              style={{
                padding: "10px",
                marginTop: "10px",
                marginBottom: "10px",

                borderRadius: 9,
                backgroundColor: p.color,
                color:
                  colorContrast(p.color, "white") >
                  colorContrast(p.color, "black")
                    ? "white"
                    : "black",
              }}
            >
              {p.name}
            </div>
          )
        );
      })}
      <Button onClick={leaveRunningGame} title="Spiel verlassen"></Button>
    </StyledContainer>
  );
};

export default GameFinishedDialog;
