import React from "react";
import DialogHeader from "./DialogHeader";
import { ActionSheet, ActionSheetOutSide } from "./ActionSheet";
import Dialog from "./Dialog";
import { chooseTerraforming } from "../api/game";

const terras = ["holz", "stein", "getreide", "wasser"];

const TransformDialog = () => {
  return (
    <>
      <ActionSheetOutSide></ActionSheetOutSide>
      <ActionSheet>
        <Dialog style={{ zIndex: 102 }}>
          <DialogHeader
            title={"Bitte wähle die Art des Terraforming"}
          ></DialogHeader>
          <div
            style={{
              display: "inline-flex",
              flexDirection: "row",
              flexWrap: "wrap",
              justifyContent: "center",
            }}
          >
            {terras.map((terra) => (
              <div
                className={"field " + terra}
                onClick={() => chooseTerraforming(terra)}
              >
                <div className="bg" />
                <div className="terrain"></div>
              </div>
            ))}
          </div>
        </Dialog>
      </ActionSheet>
    </>
  );
};

export default TransformDialog;
