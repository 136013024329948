import React from "react";

import styled from "styled-components";
import { Colors } from "../theme";

interface Props {
  title: string;
  onClick: React.MouseEventHandler;
  style?: React.CSSProperties;
  active: boolean;
}

const ToggleButton = ({ title, onClick, style, active }: Props) => {
  const OuterButton = styled.div`
    border: 5px solid ${Colors.color1};
    background-color: ${active ? Colors.color3 : Colors.color2};
    padding: 15px;
    border-radius: 9px;
  `;
  const InnerButton = styled.div`
    font-size: 30px;
    color: white;
  `;

  return (
    <OuterButton onClick={onClick} style={style}>
      <InnerButton>{title}</InnerButton>
    </OuterButton>
  );
};

export default ToggleButton;
